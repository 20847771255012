import React from 'react';
import styles from './Template.module.scss';
import jsonData from '../../footersTemplates/templatesCode.json';

const Template = ({copy, templateData}) => {

    const copyTemplate = (e) => {
        var sel, range;
        var el = e.currentTarget.parentNode.childNodes[0].childNodes[2]; //get element id\

        if (window.getSelection && document.createRange) { //Browser compatibility
            sel = window.getSelection();
            if(sel.toString() === ''){ //no text selection
                window.setTimeout(function(){
                range = document.createRange(); //range object
                range.selectNodeContents(el); //sets Range
                sel.removeAllRanges(); //remove all ranges from selection
                sel.addRange(range);//add Range to a Selection.
                document.execCommand('copy');
            },100);
            }
        }else if (document.selection) { //older ie
            sel = document.selection.createRange();
            if(sel.text === ''){ //no text selection
                range = document.body.createTextRange();//Creates TextRange object
                range.moveToElementText(el);//sets Range
                range.select(); //make selection.
                document.execCommand('copy');
            }
        }

    }

    const renderTemplate = (templateData) => {

            if(!/\s/.test(templateData.tel)){
                if(templateData.tel[3]!=' ' || templateData.tel[7]!=' '){

                    var slice1 = templateData.tel.slice(0, 3);
                    var slice2 = templateData.tel.slice(3, 6);
                    var slice3 = templateData.tel.slice(6, 9);
                    var newTel = slice1 + " " + slice2 + " " + slice3;
                }
            }

            if(templateData.przedtel){
                if(newTel){
                    newTel = templateData.przedtel + " " + newTel;
                }
                else{
                    var newTel = templateData.przedtel + " " + templateData.tel;
                }
            }

            var Template = jsonData[templateData.city][templateData.template];
            Template = Template.replace('%name%', templateData.name);
            Template = Template.replace('%surname%', templateData.surname);
            Template = Template.replaceAll('%email%', templateData.email);
            Template = Template.replaceAll('%tel%', newTel?newTel:templateData.tel);
            Template = Template.replace('%workplace%', templateData.workplace);
            Template = Template.replace('%department%', templateData.department);

        return(
            <>
                <div className={styles.template} id="toBeCopied" dangerouslySetInnerHTML={{ __html: Template}}></div>
            </>
        )
    }

    return(
        <>
            <div className={styles.wrapper}>
                {renderTemplate(templateData)}
                <button onClick={copyTemplate}>{copy}</button>
            </div>
        </>
    )
}

export default Template;