import React from 'react';
import Link from '../components/Link/Link';
import styles from './Root.module.scss';

const Root = () => (

  <div className={styles.wrapper}>
      <Link href='/Gdansk' name="Gdańsk"/>
      <Link href='/Duchnice' name="Duchnice"/>
      <Link href='/Utrecht' name="Utrecht"/>
      <Link href='/Amsterdam' name="Amsterdam"/>
  </div>
);

export default Root;