import React, { useEffect, useState } from 'react';
import Form from '../components/Form/Form';
import Data from '../footersTemplates/templatesThumbs.json';
import styles from './Views.module.scss';
import './style.css';
import Template from '../components/Template/Template';


var PreviusElem = 0;
const GdanskData = Object.values(Data.Gdansk);
var beforTel = false;
var templateContainsData = false;
var tempData;
var templatenamme;


function Gdansk() {

    const [templateData, setTemplateData] = useState();
    const [displayForm, setdisplayForm] = useState(false);
    const [formError, setformError] = useState();
    const [templateName, settemplateName] = useState();

    const radioSelect = (e) => {

        settemplateName(e.target.parentNode.parentNode.dataset.template);
        templatenamme = e.target.parentNode.parentNode.dataset.template;

        if(PreviusElem !== 0)PreviusElem.classList.remove('filled');
        PreviusElem = e.target.parentNode.parentNode.childNodes[1].childNodes[0];
        PreviusElem.classList.add('filled');

        if(templateContainsData){
            tempData.template = templatenamme;
            setTemplateData(tempData);

        }else{
            setdisplayForm(true);
        }
    };

    const getFooterData = (e) =>{

        e.preventDefault();
        const pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
        if((e.target[2].value != "") && !pattern.test(e.target[2].value)){
            setformError("Nie prawidłowy E-mail");
            return false;
        }
        setformError(false);

        const data = {
            name: e.target[0].value,
            surname: e.target[1].value,
            email: e.target[2].value,
            tel: (e.target[6].value!="")?e.target[3].value + " " + e.target[4].value:e.target[3].value,
            workplace: (e.target[6].value!="")?e.target[5].value:e.target[4].value,
            department: (e.target[6].value!="")?e.target[6].value:e.target[5].value,
            template: templateName,
            city: "Gdansk"
        }
        templateContainsData = true;
        tempData = data;
        setTemplateData(data);
    }

    const showThumb = (e) =>{
        var newImage = document.createElement("img");
        var link = e.target.dataset.image;
        newImage.setAttribute("src", process.env.PUBLIC_URL + e.target.dataset.image);
        newImage.setAttribute("id", "hoveredImage");
        e.target.parentNode.parentNode.parentNode.parentNode.insertBefore(newImage, e.target.parentNode.parentNode.parentNode);
    }

    const hideThumb = (e) =>{
        if(e.target.parentNode.parentNode.parentNode.parentNode.childNodes[1]){
            e.target.parentNode.parentNode.parentNode.parentNode.removeChild(e.target.parentNode.parentNode.parentNode.parentNode.childNodes[1]);
        }
    }

    return (
        <>
            <h1 className={styles.header}>Gdańsk</h1>
            <div className={styles.row}>
                <div className={styles.templates}>
                    <h1><i></i>Wybierz Szablon:<br/>Choose Template</h1>
                    <div className={styles.scrolableBox}>
                        {GdanskData.map((item) => (
                            <div key={item.name} className={styles.template} data-template={item.name}>
                                <div className={styles.clickArea}>
                                    <span onClick={radioSelect} onMouseEnter={showThumb} onMouseLeave={hideThumb} data-image={item.image}></span>
                                </div>

                                <div className={styles.content}>
                                    <i className={styles.radioIcon}></i>
                                    <span className={styles.name}> {item.nameDisplayed} </span>
                                    <p className={styles.description}> {item.description} </p>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
                <div className={displayForm?styles.formBox:styles.hidden}>
                    <Form
                        submitFn={getFooterData}
                        name="Imię / Name"
                        surname="Nazwisko / Surname"
                        email="E-mail"
                        tel="Telefon / Phone"
                        workplace="Stanowisko / Job Title"
                        department="Dział"
                        generate="Generuj / Generate"
                        title='Podaj swoje dane: Insert your data:'
                        error={formError}
                        beforTel={beforTel}
                    />
                </div>
                {templateData? <Template copy="Kopiuj / Copy" templateData={templateData} /> : <div></div>}
            </div>
        </>
    );
}

export default Gdansk;