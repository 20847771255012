import React from 'react';
import styles from './Form.module.scss';

var ableToGenerate = true;

const Form = ({submitFn, name, surname, email, tel, tel1, tel2, beforTel1, beforTel2, workplace, department, generate, title, error, beforTel}) => {

    return(
        <form onSubmit={submitFn} className={styles.form}>
            <h1 className={styles.header}>{title}</h1>
            <div className={styles.formItem}>
                <input placeholder=" " name="name" id="name" required className={styles.input} />
                <label htmlFor="name" className={styles.label}>{name}</label>
                <div className={styles.formItemBar}></div>
            </div>

            <div className={styles.formItem}>
                <input placeholder=" " name="surname" id="surname" className={styles.input} />
                <label htmlFor="surname" className={styles.label}>{surname}</label>
                <div className={styles.formItemBar}></div>
            </div>

            <div className={styles.formItem}>
                <input placeholder=" " name="email" id="email" className={styles.input} />
                <label htmlFor="email" className={styles.label}>{email}</label>
                <div className={styles.formItemBar}></div>
            </div>

            {tel1? 
            <>
            <div className={styles.formItemTel}>

                <select name="beforeTel1">
                    {beforTel1.map(item => (
                        <option key={item}>{item}</option>
                    ))}

                </select>
                <input placeholder=" " name="tel1" id="tel1" className={styles.input} />
                <label htmlFor="tel1" className={styles.label}>{tel1}</label>
                <div className={styles.formItemBar}></div>
            </div>

            <div className={styles.formItemTel}>

                <select name="beforeTel2">
                    {beforTel2.map(item => (
                        <option key={item}>{item}</option>
                    ))}

                </select>
                <input placeholder=" " name="tel2" id="tel2" className={styles.input} />
                <label htmlFor="tel2" className={styles.label}>{tel2}</label>
                <div className={styles.formItemBar}></div>
            </div></>:beforTel?
            <div className={styles.formItemTel}>

                <select name="beforeTel">
                    {beforTel.map(item => (
                        <option key={item}>{item}</option>
                    ))}

                </select>
                <input placeholder=" " name="tel" id="tel" className={styles.input} />
                <label htmlFor="tel" className={styles.label}>{tel}</label>
                <div className={styles.formItemBar}></div>
            </div>:<div className={styles.formItem}>
                <input placeholder=" " name="tel" id="tel" className={styles.input} />
                <label htmlFor="tel" className={styles.label}>{tel}</label>
                <div className={styles.formItemBar}></div>
            </div>}

            <div className={styles.formItem}>
                <input placeholder=" " name="workplace" id="workplace" className={styles.input} />
                <label htmlFor="workplace" className={styles.label}>{workplace}</label>
                <div className={styles.formItemBar}></div>
            </div>

            <div className={styles.formItem}>
                <input placeholder=" " name="department" id="department" className={styles.input} />
                <label htmlFor="department" className={styles.label}>{department}</label>
                <div className={styles.formItemBar}></div>
            </div>
            {error?<div className={styles.error}>{error}</div>:null}
            {ableToGenerate?<button type="submit">{generate}</button>:<button type="submit" className={styles.disabled} disabled>{generate}</button>}
        </form>
    )
}

export default Form;